import { computed, unref, type MaybeRef } from 'vue'
import { useSocketData } from '@autobid/web-socket/src/helper'
import type { FullCar } from '@autobid/ui/types/Car'
import { useCountdown } from '../../useCountdown'
import { useAuction } from '../../useAuction'

type CountdownCar = Pick<
  FullCar,
  | 'auctionId'
  | 'bids'
  | 'hotbidStartTime'
  | 'auctionStartDate'
  | 'inActiveHotbidPhase'
  | 'hotbidDuration'
>

export const useCarCountdownTarget = (car: MaybeRef<CountdownCar>) => {
  const targetTime = computed(() => {
    const _car = unref(car)
    if (!_car) {
      return new Date()
    }

    const lastBidTimestamp = _car.bids[0]?.timestamp
    const carTimestamp = _car.hotbidStartTime ?? _car.auctionStartDate

    // before hotbid
    if (!_car.inActiveHotbidPhase) return new Date(carTimestamp)

    const finalTimestamp =
      lastBidTimestamp && lastBidTimestamp > carTimestamp
        ? lastBidTimestamp
        : carTimestamp

    return new Date(
      new Date(
        new Date(finalTimestamp).getTime() + 1000 * _car.hotbidDuration.active
      ).toISOString()
    )
  })

  return {
    targetTime
  }
}

export function useCarCountdown(car: MaybeRef<CountdownCar>) {
  const { targetTime } = useCarCountdownTarget(car)

  const { socketTimeOffset } = useSocketData()
  const { auction } = useAuction(unref(car)?.auctionId)

  const overrideCurrentTime = computed(() => {
    if (!auction.value?.isPaused) return null
    return new Date(auction.value?.pauseDate)
  })

  return useCountdown(targetTime, socketTimeOffset, overrideCurrentTime)
}
