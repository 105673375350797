import type { MaybeRef } from 'vue'

const STOP_TIMER_AT = -5

export function useCountdown(
  targetTime: MaybeRef<Date>,
  offset: Ref<number>,
  overrideCurrentTime?: MaybeRef<Date>
) {
  const currentTime = ref(new Date(new Date().getTime() + (offset?.value ?? 0)))
  const { t } = useI18n()

  const isCountdownOver = computed(() => {
    if (unref(overrideCurrentTime)) {
      return unref(overrideCurrentTime) >= unref(targetTime)
    }
    return currentTime.value >= unref(targetTime)
  })

  const timeRemaining = computed(() => {
    let timeDiff = unref(overrideCurrentTime)
      ? unref(targetTime).getTime() - unref(overrideCurrentTime).getTime()
      : unref(targetTime).getTime() - currentTime.value.getTime()

    if (timeDiff < STOP_TIMER_AT) {
      stopTimer()
      timeDiff = 0
    }

    const days = Math.floor(timeDiff / (24 * 60 * 60 * 1000))
    const remainingTime = timeDiff % (24 * 60 * 60 * 1000)
    const hours = Math.floor(remainingTime / (1000 * 60 * 60))
    const minutes = Math.floor((remainingTime / (1000 * 60)) % 60)
    const seconds = Math.floor((remainingTime / 1000) % 60)

    const items = {
      days,
      hours,
      minutes,
      seconds
    }

    const countdown = Object.entries(items).filter(
      (item, index, itemsArray) => {
        const [, value] = item

        // seconds should be always returned
        if (index === itemsArray.length - 1) return true

        const allValuesBeforeAreZero = itemsArray
          .slice(0, index)
          .every(([_unit, timeValue]) => !timeValue)

        if (value <= 0 && (index === 0 || allValuesBeforeAreZero)) return false

        return true
      }
    )

    if (!Object.keys(countdown).length) {
      // to return "0 seconds" if time is up
      countdown.push(['seconds', 0])
    }

    const values = countdown.reduce((acc, [pluralKey, count]) => {
      const countWithLeadingZero = count.toString().padStart(2, '0')

      const singularKey = pluralKey.slice(0, -1)
      const key = count === 1 ? singularKey : pluralKey
      acc[key] = t(`countdown.${key}`, { count: countWithLeadingZero })
      acc[`${pluralKey}Short`] = t(`countdown.${pluralKey}-short`, {
        count: countWithLeadingZero
      })
      return acc
    }, {})

    return {
      full: t('countdown.countdown', values),
      short: t('countdown.countdown-short', values)
    }
  })

  const intervalId = ref<NodeJS.Timeout | null>(null)

  const startTimer = () => {
    const updateTime = () => {
      currentTime.value = new Date(new Date().getTime() + (offset?.value ?? 0))
    }

    updateTime()

    intervalId.value = setInterval(() => {
      updateTime()
    }, 1000)
  }

  function stopTimer() {
    if (intervalId.value) {
      clearInterval(intervalId.value)
      intervalId.value = null
    }
  }

  onMounted(startTimer)

  onUnmounted(stopTimer)

  watch(targetTime, (newVal) => {
    if (process.client && newVal && !intervalId.value) {
      startTimer()
    }
  })

  return {
    isCountdownOver,
    timeRemaining
  }
}
